import React from 'react';
import SEO from '../../components/seo';


function FullStackDeveloper() {
  return (
    <>
      <SEO title="Senior Full Stack Developer" />
      <div className="container-fluid display-6 mb-6">
        <h1 className="font-weight-bolder underscore">Senior Full Stack Developer</h1>
        <p>
        We’re looking for multiple full-time Senior Full Stack Developers to handle our various spin-outs and commercial products.
        </p>
        <p>
        Artlimes is an Innovation Technology company building state-of-the-art solutions that connect humans with technology.
        </p>
        <p>
        We are sector-agnostic and use AI, Machine Learning and the latest integration patterns to revolutionise industries that benefit from advancements in technology. Our real-time solutions help minimise human intervention and reduce the risk of failure due to human error in Shipping, Healthcare, Manufacturing, Transport and beyond.
        </p>
        <b>Responsibilities</b>
        <ul>
          <li>Develop front-to-back solutions that are modular, clean and scalable</li>
          <li>Implement solutions in ReactJS, Node, LESS /SCSS</li>
          <li>Setup and maintain NoSQL databases in MongoDB (or similar)</li>
          <li>Setup and Maintain Postgres databases</li>
          <li>Bootstrapping frameworks for clients from scratch (Gatsby, Webpack, etc)</li>
          <li>Mentor other team members on existing and upcoming principles</li>
        </ul>

        <b>About You</b>
        <ul>
          <li>You are proactive</li>
          <li>You are dedicated</li>
          <li>You must have strong analytical skills and the ability to deliver functional solutions fast and reliably</li>
          <li>You have proven experience in developing solutions for IoT and web technologies</li>
          <li>You understand what needs to go into delivering AI products</li>
          <li>You are used to working in a fast-paced environment</li>
          <li>You must have excellent communication, writing and presentational skills in both Greek and English</li>
          <li>You must have a good team spirit and be able motivate and mentor other members of your team</li>
        </ul>
        <p>
        You will be exposed to some of the latest tech stacks on the market, contribute to open source communities and apply some of the latest development principles used in the UK and the US.
        </p>
        <b>We require all applicants to have the following:</b>
        <ul>
          <li>MSc in Computer Science or similar</li>
          <li>6 years minimum professional Javascript experience</li>
          <li>3 years professional NodeJS experience</li>
          <li>2 year minimum personal or professional React experience</li>
          <li>A portfolio building responsive Javascript WebApps</li>
          <li>Knowledge of Docker</li>
          <li>Understanding of ES6 principles</li>
          <li>Excellent knowledge of Bootstrap UI, HTML5, LESS, SASS and CSS3 standards</li>
          <li>A Github account</li>
          <li>Flexible using Linux and the console</li>
          <li>Fluent written and spoken English</li>
          <li>Very good communication and problem solving</li>
        </ul>
        <b>Bonus Skills</b>
        <ul>
          <li>Docker</li>
          <li>Meteor</li>
          <li>GraphQL</li>
          <li>React Native</li>
          <li>Reaction Commerce</li>
          <li>Cloud server management</li>
          <li>Deployments, builds or other CI experience</li>
        </ul>
        <b>You’ll get benefits</b>
        <p>For all our members at Artlimes we offer:</p>
        <ul>
          <li>Xmas/Easter/Summer salary contributions</li>
          <li>IKA insurance</li>
          <li>Paid Holidays</li>
          <li>Sick Pay</li>
          <li>Stock options</li>
          <li>Quick salary increase based on performance</li>
          <li>All your usual beverages, fruits and meals</li>
        </ul>
        <p>Apply if you are looking for great career prospects with one of the most innovative companies in the field of technology</p>
        <a className="btn btn-info" href="https://forms.gle/qf3BxRFJxgwP6Dsm9" target="_blank">Apply now </a>
      </div>
    </>
  );
}

export default FullStackDeveloper;
